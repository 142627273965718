const StringsComuns = {
    en: {
        components: {
//---------------------------------HEADER---------------------------------------------------//
          routes: {
            workflow: "",
            about:"",
            test:"Submit your pitch",
          },
          header: {
            title: "✨ Expitch is free to use and is intended for research and educational purposes only."
          },

//---------------------------------HEADER---------------------------------------------------//

//---------------------------------HOME-ABOUT---------------------------------------------------//
          about: {

            title1: "Startup Insights with",
            title2: " Research-Backed Assessments",
            paragraph:"Analyze your startup with the world’s #1 AI diagnostic tool and get an academic, data-driven report across the eight CFA factors. ",
            paragraph2: "See how it works",
            pitch: {
              title: "Your pitch",
              paragraph: "We believe in the power of exceptional user experiences. Our startup is dedicated to creating visually stunning and intuitive digital products that captivate users and drive business growth. Through our meticulous design process, we blend creativity, user-centricity, and technical expertise to craft interfaces that not only look beautiful but also deliver seamless interactions. Join us on a journey to transform your digital presence and leave a lasting impression on your audience."
            },
            analysis: {
              title: "Analysis",
              features: {
                title: "Features and Benefits",
                evaluation: {
                  title: "Evaluation",
                  paragraph: "The Readiness item in your pitch was reasonably addressed, but there is room for improvement. While you briefly mentioned the readiness of your startup, you could have provided more specific details to demonstrate your preparedness for launching and delivering on your promises. Providing concrete evidence and showcasing your readiness factors would strengthen this section."
                },
                recommendation: {
                  title: "Recommendation",
                  paragraph: "To enhance the Readiness item in your pitch, I recommend providing more specific and compelling details about your startup's readiness to execute and deliver on your promises. Highlight key factors such as the qualifications and experience of your team members, any relevant past successes or achievements, your existing infrastructure, partnerships or collaborations, and any milestones or accomplishments reached during the development phase. These specifics will demonstrate your preparedness and instill confidence in potential clients or investors."
                }
              },
              barrier: {
                title: "Barrier to entry",
                evaluation: {
                  title: "Evaluation",
                  paragraph: "The Readiness item in your pitch was reasonably addressed, but there is room for improvement. While you briefly mentioned the readiness of your startup, you could have provided more specific details to demonstrate your preparedness for launching and delivering on your promises. Providing concrete evidence and showcasing your readiness factors would strengthen this section."
                },
                recommendation: {
                  title: "Recommendation",
                  paragraph: "To enhance the Readiness item in your pitch, I recommend providing more specific and compelling details about your startup's readiness to execute and deliver on your promises. Highlight key factors such as the qualifications and experience of your team members, any relevant past successes or achievements, your existing infrastructure, partnerships or collaborations, and any milestones or accomplishments reached during the development phase. These specifics will demonstrate your preparedness and instill confidence in potential clients or investors."
                }
              },
              readiness: {
                title: "Readiness",
                evaluation: {
                  title: "Evaluation",
                  paragraph: "The Readiness item in your pitch was reasonably addressed, but there is room for improvement. While you briefly mentioned the readiness of your startup, you could have provided more specific details to demonstrate your preparedness for launching and delivering on your promises. Providing concrete evidence and showcasing your readiness factors would strengthen this section."
                },
                recommendation: {
                  title: "Recommendation",
                  paragraph: "To enhance the Readiness item in your pitch, I recommend providing more specific and compelling details about your startup's readiness to execute and deliver on your promises. Highlight key factors such as the qualifications and experience of your team members, any relevant past successes or achievements, your existing infrastructure, partnerships or collaborations, and any milestones or accomplishments reached during the development phase. These specifics will demonstrate your preparedness and instill confidence in potential clients or investors."
                }
              }
            },
            card1: {
              icon: "🗣️",
              title: "Pitch analysis",
              paragraph: "Get your startup evaluated, exactly as you'd pitch it in real life. Submit your audio/video or text pitch, or record live"
            },
            card2: {
              icon: "🦾",
              title: "AI-powered analysis",
              paragraph: "While tools like ChatGPT are meant for general conversations, our AI is trained and specialized in evaluating startup pitches"
            },
            card3: {
              icon: "👍🏻",
              title: "Detailed feedback",
              paragraph: "Receive comprehensive feedback with grades for each aspect of your pitch and improve by implementing the recommendations!"
            },
            card4: {
              icon: "💯",
              title: "What is the CFA?",
              paragraph: "The Critical Factor Assessment is a proven startup evaluation framework developed by the Canadian Innovation Centre"
            }
          },

//---------------------------------HOME-ABOUT---------------------------------------------------//

//---------------------------------HOME-WORDFLOW---------------------------------------------------//

          workflow: {
            title: "How it works?",
            button: {
              text: "Play",
              title: "See how it works"
            },
            card1: {
              icon: "🎤",
              highlight: "Upload",
              paragraph: " a file or record your pitch using the built-in voice recording feature"
            },
            card2: {
              icon: "📄",
              highlight: "Convert",
              paragraph: " your voice recording to text format"
            },
            card3: {
              icon: "🤖",
              highlight: "Our AI analyzes",
              paragraph: " and grades your pitch across multiple factors"
            },
            card4: {
              icon: "🎯",
              highlight: "feedback and scores",
              paragraph1: "Get detailed ",
              paragraph2: " for every factor"
            }
          },

//---------------------------------HOME-ABOUT---------------------------------------------------//

//---------------------------------HOME-GETSTARAT---------------------------------------------------//

          getstart: {
            title: "",
            paragraph: "Your AI advantage",
            pitch: {
              title: "Pitch",
              paragraph: "Boost your chances of success with",
              highlight: "iterative pitching and A/B testing"
            },
            analysis: {
              title: "Analysis",
              paragraph1: "Get comprehensive ",
              paragraph2: " and expert ",
              paragraph3: " for improvement",
              highlight1: "evaluations",
              highlight2: "recommendations",
              button: {
                download: "Download",
                copy: "Copy"
              },
              feature: {
                title: "Features and Benefits",
                evaluation: "Evaluation",
                evaluationtext: "While you touched on the features of your agency, you did not sufficiently emphasize the specific benefits that clients can expect from working with you. To make this section more impactful, it is important to clearly articulate the advantages and value proposition your agency offers, highlighting how your features directly address the pain points and needs of potential clients.",
                recommendation: "Recommendation",
                recommendationtext: "Try to focus more on the unique benefits that clients will gain from your agency's features. Clearly communicate how your features translate into tangible advantages, such as enhanced user experiences, increased user engagement, improved conversion rates, streamlined workflows, and the achievement of clients' business goals. By doing so, you can effectively differentiate your agency from competitors and persuade potential clients to choose your services."
              },
              readiness:{
                title: "Readiness",

              }
            },
            public: {
              title: "Public",
              paragraph1: "Gain insights and learn from a diverse range of pitches by ",
              paragraph2: " those shared by our ",
              highlight1: "exploring",
              highlight2: "community",
              textbox: {
                name: "Oliva Martinez",
                fitmenow: "FitMeNow",
                paragraph1: "Hi everyone! My name is Olivia, and I'm here to introduce you to FitMeNow, the revolutionary fitness app designed to transform your workout routine. Are you tired of feeling unmotivated and struggling to reach your fitness goals? FitMeNow is here to change that!",
                paragraph2: "We've identified a common problem among individuals who want to lead a healthier lifestyle: lack of personalized guidance and motivation. Many people find it challenging to stay consistent with their exercise routines or don't know where to begin. FitMeNow is the solution to these obstacles."
              }
            }
          },

//---------------------------------HOME-ABOUT---------------------------------------------------//

//---------------------------------HOME-INSPIRING---------------------------------------------------//

          inspiring: {
            title: "Inspiring Voices,",
            red_title: "Incredible Results",
            card1: {
              content:
                "Using this platform has been a game-changer for our team. The detailed analysis and recommendations have significantly improved our pitching skills. Highly recommended!",
              name: "John",
            },
            card2: {
              content:
                "I was skeptical at first, but after using this website, I saw a noticeable improvement in my pitch delivery. The personalized feedback and grades helped me identify areas for improvement. It's an invaluable resource!",
              name: "Sarah",
            },
            card3: {
              content:
                "As an investor, I've come across countless pitches. This platform provides a unique and objective evaluation of pitches, making it easier for me to identify the most promising opportunities. It saves me time and helps me make better investment decisions.",
              name: "David ",
            },
            card4: {
              content:
                "The pitch analysis feature on this website is fantastic! It not only evaluates your pitch but also provides recommendations to enhance your delivery, content, and overall impact. It has been a tremendous help in refining my pitches.",
              name: "Emily",
            },
            card5: {
              content:
                "I recommend this website to all my clients who want to improve their pitching skills. The comprehensive analysis, along with the grades and recommendations, gives them a clear roadmap for enhancing their pitches. It's a must-have tool for anyone looking to make a strong impression.",
              name: "Mark ",
            },
            card6: {
              content:
                "I've seen incredible progress in the entrepreneurs I mentor who have used this platform. The analysis provided is insightful, and the recommendations are spot-on. It's an excellent resource for honing presentation skills and gaining confidence.",
              name: "Jessica ",
            },
            card7: {
              content:
                "This platform has revolutionized the way I approach sales pitches. The analysis provided helped me understand my strengths and weaknesses, allowing me to tailor my pitches for maximum impact. It's an invaluable tool for anyone in sales.",
              name: "Michael",
            },
            card8: {
              content:
                "I love how user-friendly and effective this website is. The pitch analysis feature is incredibly accurate and provides actionable insights. It has helped me refine my messaging, improve my storytelling, and ultimately achieve better results in my marketing campaigns.",
              name: "Rachel",
            },
          },
    

//---------------------------------HOME-INSPIRING---------------------------------------------------//

//---------------------------------HOME-RESEARCH---------------------------------------------------//

          research: {
            title: "About Our Research",
            paragraph:
              "We achieved a 79% accuracy in forecasting which startups secure business angel funding by analyzing 600 verbal startup pitches using our custom CFA-AI. Utilizing an LLM, we extracted key factors based on the Critical Factor Assessment (CFA), which evaluates startups across eight essential business dimensions.By inputting these synthesized factors into a Naive Bayes model, we discovered that focusing on specific combinations significantly enhances prediction performance (F1 score of 0.85, average precision of 0.84).",
            link: "Read the research",
            member_1: {
              name: "Yan Katcharovski",
              responsible: "Founder & Head of Research",
              university: "M.A.Sc - Engineering York University",
            },
            member_2: {
              name: "Dr. Andrew Maxwell",
              responsible: "Research Supervisor",
              university: "Ph.D - Tech. Entrepreneurship University of Waterloo",
            },
            member_3: {
              name: "Semi Hasaj",
              responsible: "ML Advisor",
              university: "MBAn - Business Analytics MIT, C3.ai",
            },
            member_4: {
              name: "Ron Tal",
              responsible: "ML Advisor",
              university: "M.A.Sc - Engineering YorkU, Ex-Uber, Coinbase",
            },
          },
          experiment: {
            title: "Ready to take your startup to the next level?",
            button: "Submit your pitch",
            content: "It's totally free!",
          },
    
//---------------------------------HOME-RESEARCH---------------------------------------------------//


//---------------------------------TEST---------------------------------------------------//

          email: {
            pitch:{
              title: "Pitch",
              paragraph: "Hi everyone! My name is Olivia, and I'm here to introduce you to FitMeNow, the revolutionary fitness app designed to transform your workout routine. Are you tired of feeling unmotivated and struggling to reach your fitness goals? FitMeNow is here to change that! We've identified a common problem among individuals who want to lead a healthier lifestyle: lack of personalized guidance and motivation. Many people find it challenging to stay consistent with their exercise routines or don't know where to begin. FitMeNow is the solution to these obstacles."
            },
            form: {
              name: "Oliva Martinez",
              title: "FitMeNow",
              favorite: "361",              
              comment: "1k",
              score: "68 ",
              total: "/ 80"
            },
            player: {
              time: "0:05 / 0:56"
            },
            textbox: {
             download: "Download" ,
             copy: "Copy",
             paragraph: "Please enter your email address, it's 100% free to get results",
             label: "Your email address ",
             button: "Uploader",
             terms: "By using this service you agree to the ",
             termsLink: "Terms & Conditions",
            }
          },
          
          tabs: {
            tab1: "Upload a file",
            tab2: "Enter text",
            tab3: "Record your voice",
          },
          uploading: {
            title: "Ready to pitch? ✨",
            uploadingbox: {
             hint: "Drag & drop or upload a file",
             drop: "Drop file..."
            },
            recording:{
              title: 'Click to start recording',
              paragraph1: "Upload a pitch of your startup",
              paragraph2: "MP3 & MP4 up to 50 MB",
              button: "Or record your voice",
              hint1: "Click the button and start talking",
              hint2: "You have 5 minutes."
            },
            submit: {
              button: "Upload",
            },
            status: {
              recording: {
                text: "Recording..."
              },
              analysis: {
                text: "Your recording",
                button: "Analyze",
                button2: "Upload again",
                button3: "Record again",
                submit: "Submit"

              }
            }
            
          },

          pasting: {
            textbox: {
              label: "Your pitch"
            },
            button: "Analyze"
          },

          pitchexamples: {
            title: "Don’t have a pitch?",
            subtitle: "See how it works with famous pitches",
            pitches: {
              pitch1: "GitLab at YCombinator 2015",
              pitch2: "DoorDash Pitch at YCombinator 2013",
              pitch3: "Coinbase at YCombinator 2012"
            },
            button: "Test"
          },

          process: {
            status_processing: "Processing... Just a moment!",
            status_waiting: "Waiting for results...",
            title: "The possible ratings for the CFA-AI report include:",
            paragraph1: "Golden Opportunity (80-100%): This factor is a tailwind propelling you to success. Keep up the excellent work and ride this momentum.",
            paragraph2: "Pivotal Potential (40-79%): Attention needed! A tweak or adjustment here could significantly elevate your chances of success.",
            paragraph3: "Critical Hurdle (0-39%): Immediate action required! This factor is a red flag that could derail your venture if not addressed."
          },
          result:{
            title: "Your grade",
            subtitle:"We're looking for your feedback and collaboration! Email ",
            email:"yank@yorku.ca 🙏",
            slogonA: "Golden Opportunity",
            slogonB: "Pivotal Potential",
            slogonC: "Critical Hurdle",
            noticeResult: "Are you sure you want to leave this page? Your unsaved data may be lost.",
            titleL: "Analysis ",
            titleR: "(CFA-AI)",
            features: {
              title: "Features and Benefits",
              tooltip: "Does your proposed product or service offer performance advantages compared to currently deployed solutions?"
            },
            readiness: {
              title: "Readiness",
              tooltip: "How far away are you from being able to deliver completed products or services to your first revenue customer?"
            },
            barrier: {
              title: "Barrier To Entry",
              tooltip: "What is unique or patentable about your product that represents a barrier to entry for potential competitors?"
            },
            adoption: {
              title: "Adoption",
              tooltip: "Can you demonstrate that customers in your target market will purchase your product or service when it is available?"
            },
            supplychain: {
              title: "Supply Chain",
              tooltip: "Can you provide confirmation that there are no success barriers either about your supply chain, or distribution channel?"
            },
            market: {
              title: "Market Size",
              tooltip: "Is the overall size of the market and your likely market share, sufficient to generate the envisaged revenues? Further, is the overall market forecast to be large enough to be interesting?"
            },
            entrepreneur: {
              title: "Entrepreneur Experience",
              tooltip: "Do you or members of your team have any direct or relevant (entrepreneurial, industry, business) experience that can be directly applied to the challenges facing this business?"
            },
            financial: {
              title: "Financial Expectation",
              tooltip: "Do your financial projections present a persuasive argument that your company can achieve cash-flow neutrality, based on your own investment, money you can borrow, and money you can raise from external investors?"
            }
          },



//---------------------------------TEST---------------------------------------------------//

test:{
  paragraph1: "No pitch? View demo"
},

//---------------------------------FOOTER---------------------------------------------------//

          footer: {
            links: {
              link_1: "",
              link_2: "",
              link_3: "",
              link_4: "About",
              link_5: "Privacy Policy",
              link_6: "Terms & Conditions",
            },
            content: "© 2023 Expitch, Inc. All rights reserved.",
          },
    
//---------------------------------FOOTER---------------------------------------------------//
        },
    }
}

export { StringsComuns }
